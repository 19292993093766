import toDailyBarChartData from '../helpers/toDailyBarChartData'

self.onmessage = function(message) {
  switch (message.data.type) {
    case 'toDailyBarChartData':
      postMessage(toDailyBarChartData(message.data.chartData))
    default:
      return undefined
  }
}
