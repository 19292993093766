import moment from 'moment'

export default (data)=>{
  
  let days = moment().endOf('day').diff(
    moment(data[0].time).startOf('day')
  , 'days')+1
  
  let formattedData = [ { "time": moment(data[0].time).startOf('day').subtract(1, 'day').format('YYYY-MM-DD'), value: undefined } ]
  
  for (let i = 0; i < (Math.max(1, days)); i++) {
    let value = 0
    let day = moment(data[0].time).startOf('day').add(i, 'days')
    data.forEach((datum)=>{
      if(moment(datum.time).isSame(day, 'day')) {
        value += datum.increment
      }
    })
    let dayData = { "time": day.format('YYYY-MM-DD'), value: value > 0 ? value : undefined }
    formattedData.push(dayData)
  }

  return formattedData
}
